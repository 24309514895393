<template>
	<b-card>
		<div class="nav nav-tabs">
			<button 
				class="btn px-0 mr-1 mr-sm-3" 
				:class="{ selected: displayQuestion == 'btn1' }"
				@click="switchState('btn1')" 
			>
				Lapor Pajak
			</button>
			<button 
				class="btn px-0 mr-1 mr-sm-3" 
				:class="{ selected: displayQuestion == 'btn2' }"
				@click="switchState('btn2')" 
			>
				Data Diri dan Keluarga
			</button>
			<button 
				class="btn px-0 mr-1 mr-sm-3" 
				:class="{ selected: displayQuestion == 'btn3' }"
				@click="switchState('btn3')" 
			>
				Check Point 1
			</button>
			<button 
				class="btn px-0 mr-1 mr-sm-3" 
				:class="{ selected: displayQuestion == 'btn4' }"
				@click="switchState('btn4')" 
			>
				Penghasilan
			</button>
			<button 
				class="btn px-0 mr-1 mr-sm-3" 
				:class="{ selected: displayQuestion == 'btn5' }"
				@click="switchState('btn5')" 
			>
				Check Point 2
			</button>
		</div>

		<div class="pt-3" v-if="displayQuestion == 'btn1'">
			<b-table 
				striped 
				hover 
				:fields="fields"
				show-empty
				responsive
			>
				<!-- <template v-slot:cell(actions)="{ item }">
					<router-link
						to="#"
					>
						<feather-icon
							:id="`invoice-row-${item.id}-send-icon`"
							icon="EditIcon"
							class="cursor-pointer"
							size="16"
						/>
					</router-link>
					<b-tooltip
						title="Edit Data"
						class="cursor-pointer"
						:target="`invoice-row-${item.id}-send-icon`"
					/>

					<feather-icon
						:id="`invoice-row-${item.id}-preview-icon`"
						icon="TrashIcon"
						size="16"
						class="mx-1"
						@click="deleteUser(item.id)"
					/>
					<b-tooltip
						title="Delete Data"
						:target="`invoice-row-${item.id}-preview-icon`"
					/>
				</template> -->
			</b-table>
		</div>
		<div class="pt-3" v-else-if="displayQuestion == 'btn2'">
			<p>ini PILIHAN 2</p>
		</div>
		<div class="pt-3" v-else-if="displayQuestion == 'btn3'">
			<p>ini PILIHAN 3</p>
		</div>
		<div class="pt-3" v-else-if="displayQuestion == 'btn4'">
			<p>ini PILIHAN 4</p>
		</div>
		<div class="pt-3" v-else>
			<p>ini PILIHAN 5</p>
		</div>
	</b-card>
</template>

<script>
import { 
	BCard,
	BCol,
	BCardText,
	BTable,
	BPagination, 
} from 'bootstrap-vue'
export default {
	components: {
		BCard,
		BCol,
		BCardText,
		BTable,
		BPagination,
	},

	data() {
		return {
			perPage: 10,
			currentPage: 1,
			fields: ['no', 'question', 'action'],
			displayQuestion: "btn1",
		}
	},

	// computed: {
	// 	rows() {
	// 		return this.users.length
	// 	}
	// },

	methods: {
		switchState(data) {
			this.displayQuestion = data;
		}
	}
}
</script>

<style lang="scss">
	.selected {
		border-bottom: 1px solid #4F00F6;
		border-radius: 0;
	}
	.nav-tabs {
		border-bottom: 1px solid #A1A1A1;
		border-radius: 0;
	}
</style>